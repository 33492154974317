<template>
  <div>
    <h2>Not found!</h2>
    <p>Looks like something went wrong here and the page was not found.</p>
  </div>
</template>

<script>
export default {
  name: "EventStatus",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
h4 {
  @include Gilroy-Bold;
  font-size: 24px;
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
